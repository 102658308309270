function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Oxidation Communication")]),_vm._v(" "),_c('p',[_vm._v("The only scientific journal which, in its new form, is devoted to the global oxy-reduction\ninteractions proceeding in nature.")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/0209-4541","lang":"en","title":"Oxidation Communication","description":"The only scientific journal which, in its new form, is devoted to the global oxy-reduction interactions proceeding in nature.","issn":"0209-4541","image":"/images/cover-0209-4541.jpg","order":1,"restricted":true},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }