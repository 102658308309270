function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Journal of the Balkan Tribological Association")]),_vm._v(" "),_c('p',[_vm._v("International Journal edited and published by Scibulcom Ltd. for rapid scientific and other information, covering all aspects of the processes included in overall tribology, tribomechanics, tribochemistry and tribology.")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/1310-4772","lang":"en","title":"Journal of the Balkan Tribological Association","description":"International Journal edited by the Balkan Tribological Association for rapid scientific and other information, covering all aspects of the processes included in overall tribology, tribomechanics, tribochemistry and tribology.","issn":"1310-4772","image":"/images/cover-1310-4772.jpg","order":3,"restricted":true},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }