function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p'),_vm._v(" "),_c('h2',[_vm._v("Subscription Information")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',[_vm._v("Journal of Environmental Protection and Ecology (ISSN 1311-5065) is published in 6 separate issues.")]),_vm._v(" "),_c('p',[_vm._v("Regular subscription price for 2018 is: EUR 560/Europe and US$ 820/rest, 10% agency discount, plus extra postage\ncharges:")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('p',[_vm._v("Bank charges are shared according to the banks requirements.")]),_vm._v(" "),_c('p',[_vm._v("Advertising and announcements are accepted and published in the Journal at US$ 4 per 1 cm2.")]),_vm._v(" "),_c('p',[_vm._v("The paper copy of each book will be sent to the address given by you by mail.")]),_vm._v(" "),_c('p',[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/profile","type":"button"}},[_vm._v("Subscribe Now")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[_c('li',[_vm._v("for Europe EUR 30 without priority mail and EUR 60 with priority mail;")]),_vm._v(" "),_c('li',[_vm._v("for all other countries US$ 50 without priority mail and US$ 90 with priority mail, including packing, handling &\npostage.")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/1310-4772/subscription","lang":"en","title":"Subscription"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }