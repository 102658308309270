function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p'),_vm._v(" "),_c('h2',[_vm._v("Subscription Information")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',[_vm._v("Journal of Environmental Protection and Ecology (ISSN 1311-5065), Volume 26 (2025) will be published in eight separate books.")]),_vm._v(" "),_c('p',[_vm._v("Regular subscription rate for 2025 is: EUR 1190/Europe and US$ 1418/rest, 10% agency discount, plus extra postage charges:")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('p',[_vm._v("Bank charges are shared according to the banks requirements. Prices are subject to change without notice, in accordance with market fluctuations.")]),_vm._v(" "),_c('p',[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/profile","type":"button"}},[_vm._v("Subscribe Now")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[_c('li',[_vm._v("for Europe: EUR 30 without priority mail and EUR 60 with priority mail;")]),_vm._v(" "),_c('li',[_vm._v("for all other countries: US$ 50 without priority mail and US$ 90 with priority mail, including packing, handling & postage.")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/1311-5065/subscription","lang":"en","title":"Subscription for Journal of  Environmental Protection and Ecology"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }