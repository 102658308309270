function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h2',[_vm._v("Contact Us")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('address',[_c('strong',[_vm._v("SciBulCom Ltd.")]),_vm._v(" "),_c('br'),_c('em',[_vm._v("General Manager: Prof. Slavi K. Ivanov, D.Sc.")]),_vm._v(" "),_c('br'),_vm._v("P.O.Box 249\n    "),_c('br'),_vm._v("7, Nezabravka Street\n    "),_c('br'),_vm._v("1113 Sofia, BULGARIA\n    "),_c('br')]),_vm._v(" "),_c('address',[_vm._v("\n    Email: "),_c('a',{attrs:{"href":"mailto:scibulcom2@abv.bg"}},[_vm._v("scibulcom2@abv.bg")])])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/contacts","lang":"en"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }