function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"})}
var staticRenderFns = []

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journal/index","lang":"en","links":[{"name":"About","type":"about"},{"name":"Editors","type":"editors"},{"name":"Ethics","type":"ethics"},{"name":"Review Process","type":"peer"},{"name":"Submission","type":"instructions"},{"name":"Subscription","type":"subscription"}],"articleFilters":[{"label":"Title","value":"title"},{"label":"Full Text","value":"content"},{"label":"Keywords","value":"keywords"},{"label":"Abstract","value":"abstract"}],"authorFilters":[{"label":"Full Name","value":"fullName"},{"label":"Institution","value":"institutions"},{"label":"ORCID","value":"orcid"}],"availableCards":[{"name":"Issues","route":"issues"},{"name":"Articles","route":"articles"},{"name":"Authors","route":"authors"}],"items":[{"name":"JBTA","issn":"1310-4772","restricted":true},{"name":"JEPE","issn":"1311-5065","restricted":true},{"name":"OXYCOM","issn":"0209-4541","restricted":true}]},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }