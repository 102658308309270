function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_c('em',[_vm._v("Enter your profile details")])]),_vm._v(" "),_c('p',[_vm._v("You can update it anytime")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/profile/index","lang":"en","title":"Profile","save":"Save","submit":"Submit","logout":"Logout","subscriptions":{"title":"Subscriptions","columns":["Journal","ISSN","Full Text Access"],"subscribe":"Subscribe","public":"Public","dateFormat":"MM/DD/YYYY","subscribedUntil":"Subscribed Until","subscribeRequestSent":"Subscribe request sent on"},"profile":{"name":{"label":"Name","placeholder":"Ivan Ivanov"},"phone":{"label":"Phone","placeholder":"+359 2 91910"},"orcid":{"label":"ORCID","placeholder":"ORCID, eg. A-1009-2008","hint":"ORCID registration"},"institution":{"label":"Institution","placeholder":"Sofia University"}}},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }