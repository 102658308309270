function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h2',[_vm._v("Scientific Bulgarian Communications")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',[_vm._v("SciBulCom Ltd. is publishing the International Journals “Oxidation Communications” issued already 41 years, 11 in Hungary and 30 in Bulgaria, “Journal of the Balkan Tribological Association“ - an International Journal of the Balkan Tribological Association, published for 24 years and “Journal of Environmental Protection and Ecology” – the most outstanding journal devoted to ecological problems on the Balkans, published already 19 years in Republic of Bulgaria.")]),_vm._v(" "),_c('p',[_vm._v("The company is also engaged in publishing and distributing scientific monographs, books, textbooks, etc.")]),_vm._v(" "),_c('p',[_vm._v("Through our editions one can find an efficient field for contribution and publishing of advertisings.")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals","lang":"en","title":"SciBulCom, Oxidation Communication, Journal of the Balkan Tribological Association,  Journal of  Environmental Protection and Ecology","customHeader":"<img src=\"/images/logos.png\" class=\"logos\" />\n"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }