function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p'),_vm._v(" "),_c('h2',[_vm._v("Editorial Board")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h3',[_vm._v("Honorary and Founding Editor")]),_vm._v(" "),_c('p',[_vm._v("Nyagol Manolov, Bulgaria")]),_vm._v(" "),_c('h3',[_vm._v("Editor-in-Chief")]),_vm._v(" "),_c('p',[_vm._v("Slavi Ivanov, Bulgaria")]),_vm._v(" "),_c('h3',[_vm._v("Deputy Editor-in-Chief")]),_vm._v(" "),_c('p',[_vm._v("Zh. Kalitchin, Bulgaria")]),_vm._v(" "),_c('h3',[_vm._v("Associate Editors")]),_vm._v(" "),_c('ul',[_c('li',[_vm._v("Niculae Napoleon Antonescu, Romania")]),_vm._v(" "),_c('li',[_vm._v("Eng. habil. K.-D. Bouzakis, Greece")]),_vm._v(" "),_c('li',[_vm._v("Mehmet Karamis, Turkey")]),_vm._v(" "),_c('li',[_vm._v("V. Gecevska, North Macedonia")]),_vm._v(" "),_c('li',[_vm._v("Mara Kandeva, Bulgaria")])]),_vm._v(" "),_c('h3',[_vm._v("International Editorial Board")]),_vm._v(" "),_c('ul',[_c('li',[_vm._v("Vladimir Andonovic, North Macedonia")]),_vm._v(" "),_c('li',[_vm._v("Miroslav Babič, Serbia")]),_vm._v(" "),_c('li',[_vm._v("G. Haidemenopoulos, Greece")]),_vm._v(" "),_c('li',[_vm._v("H. Kaleli, Turkey")]),_vm._v(" "),_c('li',[_vm._v("A. Michailidis, Greece")]),_vm._v(" "),_c('li',[_vm._v("S. Mitsi, Greece")]),_vm._v(" "),_c('li',[_vm._v("P. St. Petkov, Bulgaria")]),_vm._v(" "),_c('li',[_vm._v("Alexandar Rač, Serbia")]),_vm._v(" "),_c('li',[_vm._v("R. G. Ripeanu, Romania")]),_vm._v(" "),_c('li',[_vm._v("Andrei Tudor, Romania")]),_vm._v(" "),_c('li',[_vm._v("G. E. Zaikov, Russia")]),_vm._v(" "),_c('li',[_vm._v("Mikolai Kuzinovski, North Macedonia")]),_vm._v(" "),_c('li',[_vm._v("Fehmi Nair, Turkey")]),_vm._v(" "),_c('li',[_vm._v("V. Pozhidaeva, Bulgaria")]),_vm._v(" "),_c('li',[_vm._v("Burhan Selcuk, Turkey")])])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/1310-4772/editors","lang":"en","title":"Editorial Board"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }