function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p'),_vm._v(" "),_c('h2',[_vm._v("Subscription Information")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',[_vm._v("Oxidation Communications (ISSN 0209-4541) Volume 44 (2021) will be published quarterly, in four separate books. The editorial board can publish special issues in case of over-accumulation of manuscripts submitted for publication.")]),_vm._v(" "),_c('p',[_vm._v("Regular subscription rate: EUR 800/Europe and US$ 910/rest, 10% agency discount, plus extra postage charges: for Europe EUR 30\nwithout priority mail and EUR 60 with priority mail; for all other countries US$ 50 without priority mail and US$ 90\nwith priority mail, including packing, handling & postage. Bank charges are shared according to the banks requirements.\nPrices are subject to change without notice, in accordance with market fluctuations.")]),_vm._v(" "),_c('p',[_vm._v("Advertising and announcements are accepted and published in the Journal at US$ 3 per 1 cm2.")]),_vm._v(" "),_c('p',[_vm._v("Subscription orders should be sent to:")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('p',[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/profile","type":"button"}},[_vm._v("Subscribe Now")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('blockquote',[_c('p',[_vm._v("Prof. Dr. Slavi K. Ivanov – SciBulCom Ltd.,\nP. O. Box 249, 7 Nezabravka Str., 1113 Sofia, Bulgaria;\n"),_c('a',{attrs:{"href":"www.scibulcom.net"}},[_vm._v("www.scibulcom.net")]),_vm._v(";\ne-mail: "),_c('a',{attrs:{"href":"scibulcom2@abv.bg"}},[_vm._v("scibulcom2@abv.bg")]),_vm._v(".")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/0209-4541/subscription","lang":"en","title":"Subscription"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }